import React from 'react';
import './About.css'; // Import your CSS file for styling
import robotImage from '../../images/robotImage.webp'; // Import the image file
import engeenering from '../../images/engeenering.jpeg'

const About = () => {
  return (
    <div className="about-container">
      <h2 className="about-header">About Us</h2>
      <div className="about-content">
        <div className="about-text">
          <p>
          Welcome to Technocom Consultants, where we specialize in delivering comprehensive engineering solutions tailored to meet your unique needs. Our expertise spans across two key areas: Basic Engineering and Detail Engineering.
          </p>
          <br></br>
          <p>
          At Technocom Consultants, we believe in the power of innovation and precision. Our team of experienced engineers and industry experts is dedicated to providing high-quality, reliable services that drive success for our clients. Whether you are embarking on a new project or seeking to enhance existing operations, we offer a wide range of engineering solutions designed to meet the most demanding specifications and standards.
          Welcome to Technocom Consultants, where we specialize in delivering comprehensive engineering solutions tailored to meet your unique needs. Our expertise spans across two key areas: Basic Engineering and Detail Engineering.
          </p>
          <br></br>
        </div>
        <div className="about-image">
          <img src={engeenering} alt="Robot" style={{ height: '250px', width: '500px' }} />
        </div>
      </div>
    </div>
  );
};

export default About;
